import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { equals } from 'ramda'
import { getGlobalArg } from 'shared'

export const activateSentry = () => {
  if (!equals('PRODUCTION', getGlobalArg('CURRENT_ENV'))) return
  Sentry.init({
    dsn:
      'https://a4bd637e304ec349a1232a8057691b69@o4505830356615168.ingest.us.sentry.io/4506547790610432',
    ignoreErrors: ['UnknownError: Background Sync is disabled', 'Method not found'],
    integrations: [
      new Integrations.BrowserTracing(),
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 1.0,

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  })
}
